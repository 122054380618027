import Box from '@mui/material/Box';
import logo from '~/static/logo.png';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';

const HEADER_HEIGHT = 64;
const CONTENT_TO_HEADER = 0;
const LOGO_WIDTH = 145;
const LOGO_HEIGHT = 35;
export const NAV_HEIGHT = HEADER_HEIGHT;
export const CONTENT_TOP = HEADER_HEIGHT + CONTENT_TO_HEADER;

const Header = ({ showLeftElement, rightElement }: any) => {
	const navigate = useNavigate();
	return (
		<>
			<Box
				sx={{
					bgcolor: 'primary.main',
					width: '100%',
					height: `${HEADER_HEIGHT}px`,
					position: 'fixed',
					zIndex: 1,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Box sx={{ position: 'absolute', left: 0, pl: 1 }}>
					{showLeftElement && (
						<IconButton color='secondary' onClick={() => navigate(-1)}>
							<KeyboardArrowLeftIcon sx={{ width: '36px', height: '36px' }} />
						</IconButton>
					)}
				</Box>
				<img src={logo} width={`${LOGO_WIDTH}px`} height={`${LOGO_HEIGHT}px`} />
				<Box sx={{ position: 'absolute', right: 0, pr: 1 }}>{rightElement}</Box>
			</Box>
		</>
	);
};

Header.defaultProps = {
	showLeftElement: false,
};
export default Header;
