import { ReactElement } from 'react';
import useGlobalState from '~/hooks/useGlobalState';
import LoginModal from '~/components/LoginModal';
interface RequireAuthProps {
	children: ReactElement;
}

const RequireAuth = ({ children }: RequireAuthProps) => {
	const { data: isAuth } = useGlobalState('isAuth');

	return !isAuth ? (
		<>
			<LoginModal />
		</>
	) : (
		<>{children}</>
	);
};
export default RequireAuth;
