import Autocomplete from '@mui/material/Autocomplete';
import { SxProps } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { get, useFormContext, Controller } from 'react-hook-form';
import type { KeyboardEventHandler } from 'react';
import FormLabel from './FormLabel';
import TextField from './TextField';

export interface Option<T = any> {
	label: Option<T> | string;
	value: Option<T> | string;
	data?: T;
}

interface SearchInputFieldProps<T> {
	name: string;
	label: string;
	required: boolean;
	disabled?: boolean;
	freeSolo?: boolean;
	disablePortal?: boolean;
	noOptionsText?: string;
	options: Option<T>[];
	placeholder?: string;
	onChange?: Function;
	defaultValue?: Option;
	renderOption?: any;
	autocompleteKey?: string;
	inputValue?: string;
	onInputChange?: (e: any, newInputValue: any) => void;
	onClose?: (event: object, reason: string) => void;
	onKeyPress?: KeyboardEventHandler<T> | undefined;
	listboxSx?: SxProps;
	filterOptions?: any;
}

const SearchInputField = <T,>({
	name,
	label = '',
	required,
	options,
	placeholder,
	onChange,
	defaultValue,
	autocompleteKey,
	listboxSx,
	...props
}: SearchInputFieldProps<T>) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value = '', onChange: onFieldChange } }) => {
				const errorMsg = get(errors, name)?.message;
				const isError = !!errorMsg;
				return (
					<Autocomplete
						key={autocompleteKey}
						isOptionEqualToValue={(option, value) =>
							typeof option === 'string'
								? option == value
								: option.value == value
						}
						// @ts-ignore-start
						ListboxProps={{ sx: listboxSx }}
						// @ts-ignore-end
						defaultValue={defaultValue}
						options={options}
						getOptionLabel={(option: any) =>
							typeof option === 'string' ? option : option?.label
						}
						popupIcon={<KeyboardArrowDownIcon />}
						renderInput={(params: any) => {
							return (
								<TextField
									label={<FormLabel required={required} label={label} />}
									name={name}
									error={isError}
									helperText={errorMsg}
									placeholder={placeholder}
									variant='standard'
									{...params}
								/>
							);
						}}
						onChange={(e, v) => {
							const val = typeof v === 'string' ? v : v?.value;
							onChange ? onChange(v) : onFieldChange(val);
						}}
						{...props}
					/>
				);
			}}
		/>
	);
};

SearchInputField.defaultProps = {
	disabled: false,
	required: false,
	freeSolo: false,
	disablePortal: false,
};

export default SearchInputField;
