import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import { BaseTextFieldProps } from '@mui/material/TextField';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputField from './InputField';

interface TextFieldProps extends BaseTextFieldProps {
	name: string;
	label: string;
	onChange?: Function;
}

const PasswordInputField = ({ type, ...props }: TextFieldProps) => {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<InputField
			type={showPassword ? 'text' : 'password'}
			maxLength={32}
			InputProps={{
				endAdornment: (
					<InputAdornment position='start'>
						<IconButton
							onClick={() => setShowPassword((status) => !status)}
							edge='end'
						>
							{showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				),
			}}
			{...props}
		/>
	);
};

export default PasswordInputField;
