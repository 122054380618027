import { ReactElement } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { FormControl, FormHelperText } from '@mui/material';
import { BaseTextFieldProps } from '@mui/material/TextField';
import { get, useFormContext, Controller } from 'react-hook-form';
import TextField from './TextField';
import FormLabel from './FormLabel';
interface TextFieldProps
	extends Omit<
		BaseTextFieldProps,
		'name' | 'label' | 'onChange' | 'InputProps' | 'inputProps'
	> {
	name: string;
	label: string;
	tooltip?: string;
	styleSize?: string;
	startAdornment?: string | ReactElement;
	endAdornment?: string;
	InputProps?: any;
	inputProps?: any;
	maxLength?: number;
	onChange?: Function;
}

const InputField = ({
	name,
	label,
	tooltip,
	value,
	styleSize,
	required = false,
	fullWidth,
	onChange,
	maxLength,
	inputProps,
	startAdornment,
	endAdornment,
	...props
}: TextFieldProps) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange: onFieldChange, onBlur, value = '' } }) => {
				const errorMsg = get(errors, name)?.message;
				const isError = !!errorMsg;
				return (
					<FormControl
						error={isError}
						sx={{ width: fullWidth ? '100%' : 'auto' }}
					>
						<TextField
							className={styleSize ? `Customized-${styleSize}` : undefined}
							label={
								<FormLabel
									required={required}
									label={label}
									tooltip={tooltip}
								/>
							}
							name={name}
							value={value}
							error={isError}
							fullWidth={fullWidth}
							onChange={(e: any) => {
								onChange ? onChange(e.target.value) : onFieldChange(e);
							}}
							onBlur={onBlur}
							InputProps={{
								startAdornment: startAdornment && (
									<InputAdornment position='start'>
										{startAdornment}
									</InputAdornment>
								),
								endAdornment: endAdornment && (
									<InputAdornment position='end'>{endAdornment}</InputAdornment>
								),
							}}
							inputProps={{
								maxLength,
								...inputProps,
							}}
							{...props}
						/>
						<FormHelperText sx={{ ml: 0 }}>{errorMsg}</FormHelperText>
					</FormControl>
				);
			}}
		/>
	);
};

InputField.defaultProps = {
	type: 'text',
	disabled: false,
	fullWidth: true,
	required: true,
	maxLength: 50,
	variant: 'standard',
	startAdornment: '',
	endAdornment: '',
};

export default InputField;
