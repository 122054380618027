import store from 'store2';
import React, { useMemo } from 'react';
import { SWRConfiguration, SWRConfig } from 'swr';
import useSnackbar from '~/hooks/useSnackbar';
import ajax from '~/utils/ajax';

interface Configuration extends Omit<SWRConfiguration, 'provider'> {
	provider?: () => any;
}

const initialState = new Map([['isAuth', store.has('token')]]);

const SWRConfigProvider = ({ children }: { children: React.ReactNode }) => {
	const pushSnackbar = useSnackbar();
	const config: Configuration = useMemo(() => {
		return {
			errorRetryCount: 0,
			revalidateOnFocus: false,
			provider: () => initialState,
			fetcher: (resource) => ajax(resource).then((res) => res.data),
			onError: (err, key, config) => {
				// TODO message check
				if (err?.message === 'Request failed with status code 403') {
					const { mutate } = config as any;
					pushSnackbar(err?.message, {
						variant: 'warning',
					});
					mutate('isAuth', false, false);
					store.remove('id');
					store.remove('token');
					store.remove('refreshToken');
				}
				return err;
			},
		};
	}, [pushSnackbar]);
	return <SWRConfig value={config}>{children}</SWRConfig>;
};

export default SWRConfigProvider;
