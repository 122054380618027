// https://github.com/vercel/swr/pull/1450
import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { AxiosRequestConfig } from 'axios';
import { useSetState, useMountedState, useUpdateEffect } from 'react-use';
import useSnackbar from './useSnackbar';

interface useSWRMutationState {
	axiosConfig: AxiosRequestConfig | null;
	data: any;
	time?: string;
	error?: Error | null;
	notification: INotification | null;
}

interface customAxiosRequestConfig extends AxiosRequestConfig {
	time?: number;
}
interface useSWRMutationOptions {
	onSuccess?: (data?: any) => any;
	onError?: (err?: Error) => any;
}

interface INotification {
	notificationOnSuccess?: boolean;
	notificationOnError?: boolean;
	successMessage?: string;
	failureMessage?: string;
}

const useSWRMutation = (config: AxiosRequestConfig, options?: useSWRMutationOptions) => {
	const { onSuccess, onError } = options || {};
	const [state, setState] = useSetState<useSWRMutationState>({
		axiosConfig: null,
		data: null,
		error: null,
		notification: null,
	});
	const isMounted = useMountedState();
	const pushSnackbar = useSnackbar();
	const { onError: configOnError } = useSWRConfig();
	const { isValidating, mutate } = useSWR(
		() => {
			if (!state.axiosConfig) {
				throw new Error('no trigger');
			}
			return state.axiosConfig;
		},
		null,
		{
			dedupingInterval: 0,
			revalidateIfStale: false,
			revalidateOnMount: false,
			onSuccess: (data) => {
				const { notificationOnSuccess = true, successMessage } = state.notification || {};
				if (notificationOnSuccess) {
					const message = successMessage || 'Success';
					pushSnackbar(message, {
						variant: 'success',
					});
				}
				if (onSuccess) {
					onSuccess(data);
				}
				if (isMounted()) {
					setState({
						data,
						axiosConfig: null,
					});
				}
				return data;
			},
			onError: (error, key, config) => {
				if (error) {
					return configOnError(error, key, config);
				}
				const { notificationOnError = true, failureMessage } = state.notification || {};
				if (notificationOnError) {
					const message = failureMessage || 'Error';
					pushSnackbar(message, {
						variant: 'error',
					});
				}
				if (onError) {
					onError(error);
				}
				if (isMounted()) {
					setState({
						error,
						axiosConfig: null,
					});
				}
				return error;
			},
		}
	);
	const trigger = useCallback(
		(overridedConfig?: customAxiosRequestConfig, notification?: INotification) => {
			if (isMounted() && !isValidating) {
				setState({
					data: null,
					error: null,
					axiosConfig: {
						...config,
						...overridedConfig,
					},
					notification,
				});
			}
		},
		[isValidating, setState, config, isMounted]
	);

	useUpdateEffect(() => {
		if (state.axiosConfig) {
			mutate();
		}
	}, [state.axiosConfig]);
	return {
		data: state.data,
		error: state.error,
		isMutating: isValidating,
		trigger,
	};
};

export default useSWRMutation;
