import React from 'react';
import * as yup from 'yup';
import { ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import SWRConfigProvider from './SWRConfigProvider';
import theme from './theme';
import App from './App';
import './index.css';
// import reportWebVitals from './reportWebVitals';

yup.setLocale({
	mixed: {
		required: 'Required',
		notType: () => {
			return 'Type Error';
		},
	},
});

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');

const root = createRoot(container);
root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<SnackbarProvider
				maxSnack={5}
				autoHideDuration={3000}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<SWRConfigProvider>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</SWRConfigProvider>
			</SnackbarProvider>
		</ThemeProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
