import {
	Box,
	Button as MuiButton,
	ButtonProps as MuiButtonProps,
} from '@mui/material';
import { SxProps } from '@mui/system';

export interface ButtonProps extends Omit<MuiButtonProps, 'onClick'> {
	onClick?: any;
	sx?: SxProps;
	loading?: boolean;
}

const Button = ({
	children,
	sx,
	loading,
	variant = 'contained',
	color = 'primary',
	size = 'medium',
	...props
}: ButtonProps) => {
	const styles = useStyles();
	return (
		<MuiButton
			variant={variant}
			color={color}
			size={size}
			sx={sx ? { ...sx, ...styles.container } : styles.container}
			{...props}
		>
			{loading ? (
				<Box sx={styles.loadingContainer}>
					<Box sx={styles.loading} />
				</Box>
			) : (
				children
			)}
		</MuiButton>
	);
};

const useStyles = () => {
	return {
		container: {
			textTransform: 'none',
			width: '100%',
		},
		loadingContainer: {
			padding: '4px 0',
		},
		loading: {
			border: '2px solid',
			animation: 'spin 1s linear infinite',
			borderTop: '2px solid',
			borderTopColor: 'transparent',
			width: '16px',
			height: '16px',
			borderRadius: '50%',
			'@keyframes spin': {
				'0%': {
					transform: 'rotate(0deg)',
				},
				'100%': {
					transform: 'rotate(360deg)',
				},
			},
		},
	};
};

export default Button;
