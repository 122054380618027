import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import RequireAuth from '~/components/RequireAuth';
import ErrorPage from '~/pages/ErrorPage';
import * as MemoPages from '~/pages/MemoPages';
import * as UserPages from '~/pages/UserPages';

const RegistrationPage = lazy(() => import('~/pages/RegistrationPage'));
const EventsPage = lazy(() => import('~/pages/EventsPage'));
const MyEventsPage = lazy(() => import('~/pages/MyEventsPage'));
const EventDetailPage = lazy(() => import('~/pages/EventDetailPage'));
const AddEventPage = lazy(() => import('~/pages/AddEventPage'));
const PrivateEventPage = lazy(() => import('~/pages/PrivateEventPage'));
const ContactPage = lazy(() => import('~/pages/ContactPage'));
const MyQRCodePage = lazy(() => import('~/pages/MyQRCodePage'));
const EventBuddyPage = lazy(() => import('~/pages/EventBuddyPage'));

const routes: RouteObject[] = [
	{
		path: '/',
		children: [
			{
				index: true,
				element: <EventsPage />,
			},
			{
				path: '/events/:id',
				element: <EventDetailPage />,
			},
			{
				path: '/events/:eventId/:userId/memo',
				element: (
					<RequireAuth>
						<MemoPages.CreateMemoPage />
					</RequireAuth>
				),
			},
			{
				path: '/events/addContact/:id',
				element: (
					<RequireAuth>
						<UserPages.AddContactPage />
					</RequireAuth>
				),
			},
		],
	},
	{
		path: '/myEvents',
		children: [
			{
				index: true,
				element: (
					<RequireAuth>
						<MyEventsPage />
					</RequireAuth>
				),
			},
			{
				path: '/myEvents/addEvent',
				element: <AddEventPage />,
			},
			{
				path: '/myEvents/eventBuddy',
				element: (
					<RequireAuth>
						<EventBuddyPage />
					</RequireAuth>
				),
			},
			{
				path: '/myEvents/addEvent/private',
				element: <PrivateEventPage />,
			},
		],
	},
	{
		path: '/contact',
		children: [
			{
				index: true,
				element: (
					<RequireAuth>
						<ContactPage />
					</RequireAuth>
				),
			},
			{
				path: '/contact/:contactId/memo',
				element: (
					<RequireAuth>
						<MemoPages.ContactMemoPage />
					</RequireAuth>
				),
			},
			{
				path: '/contact/addContact/:id',
				element: (
					<RequireAuth>
						<UserPages.AddContactPage />
					</RequireAuth>
				),
			},
		],
	},
	{
		path: '/user',
		children: [
			{
				index: true,
				element: (
					<RequireAuth>
						<UserPages.AccountPage />
					</RequireAuth>
				),
			},
			{
				path: '/user/myqrcode/:id',
				element: (
					<RequireAuth>
						<MyQRCodePage />
					</RequireAuth>
				),
			},
			{
				path: '/user/password',
				element: (
					<RequireAuth>
						<UserPages.PasswordPage />
					</RequireAuth>
				),
			},
			{
				path: '/user/edit',
				element: (
					<RequireAuth>
						<UserPages.EditUserPage />
					</RequireAuth>
				),
			},
		],
	},
	{
		path: '/registration',
		element: <RegistrationPage />,
	},
	{
		path: '*',
		element: <ErrorPage />,
	},
];

export default routes;
