import { Box, Typography } from '@mui/material';
import Watched from './Watched';
import InputField from './InputField';

interface ITextareaField {
	name: string;
	rows?: number;
	label: string;
	required?: boolean;
	maxLength?: number;
	placeholder?: string;
	readOnly?: boolean;
}
const TextareaField = ({
	name,
	rows = 3,
	maxLength = 300,
	readOnly,
	...props
}: ITextareaField) => {
	return (
		<Watched
			name={name}
			render={(value) => (
				<Box sx={{ position: 'relative' }}>
					<InputField
						name={name}
						multiline
						rows={rows}
						maxLength={maxLength}
						InputProps={{
							sx: {
								fontSize: '20px !important',
								borderRadius: '4px',
								p: 1,
							},
						}}
						variant='outlined'
						sx={{
							'& legend': {
								display: 'none',
							},
							'& fieldset': {
								top: 0,
							},
						}}
						{...props}
					/>
					{!readOnly && (
						<Typography variant='h2' sx={{ py: 1, textAlign: 'end' }}>{`${
							value ? value.length : 0
						} / ${maxLength}`}</Typography>
					)}
				</Box>
			)}
		/>
	);
};

export default TextareaField;
