import { lazy } from 'react';

const AccountPage = lazy(
	() => import('./AccountPage' /* webpackChunkName: 'AccountPage' */)
);
const AddContactPage = lazy(
	() => import('./AddContactPage' /* webpackChunkName: 'AddContactPage' */)
);

const PasswordPage = lazy(
	() => import('./PasswordPage' /* webpackChunkName: 'PasswordPage' */)
);

const EditUserPage = lazy(
	() => import('./EditUserPage' /* webpackChunkName: 'EditUserPage' */)
);
export { AddContactPage, AccountPage, PasswordPage, EditUserPage };
