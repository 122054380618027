import { CSSProperties, Fragment } from 'react';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { FormLabel as BasicFormLabel } from '@mui/material';

interface BasicFormLabelProps {
	required?: boolean;
	label?: string;
	tooltip?: string;
	labelStyle?: CSSProperties;
}

const FormLabel = ({
	required = false,
	label,
	tooltip,
	labelStyle,
}: BasicFormLabelProps) => {
	if (!label) return <Fragment />;

	return (
		<BasicFormLabel sx={{ display: 'inline-flex', mb: 0.5 }}>
			<Typography variant='inherit' sx={labelStyle}>
				{label}
			</Typography>
			{!required && (
				<Typography variant='inherit' sx={{ pl: 1 }}>
					(Optional)
				</Typography>
			)}
			{tooltip && (
				<Tooltip arrow title={tooltip} placement='top'>
					<InfoIcon fontSize='small' />
				</Tooltip>
			)}
		</BasicFormLabel>
	);
};

export default FormLabel;
