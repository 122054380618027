import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Button from '~/components/Button';
import Wrapper from '~/components/Wrapper';

const ErrorPage = () => {
	const navigate = useNavigate();
	return (
		<Wrapper>
			<Stack
				spacing={3}
				justifyContent='center'
				alignItems='center'
				sx={{ height: '60vh' }}
			>
				<Typography>Oops!</Typography>
				<Typography>Sorry, an unexpected error has occured.</Typography>
				<Button variant='contained' onClick={() => navigate(-1)}>
					Go Back
				</Button>
			</Stack>
		</Wrapper>
	);
};

export default ErrorPage;
