import Stack from '@mui/material/Stack';
import DeckIcon from '@mui/icons-material/Deck';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import ContactsIcon from '@mui/icons-material/Contacts';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import { useNavigate, useLocation } from 'react-router-dom';
import IconBox from '~/components/IconBox';
import { NAV_HEIGHT } from './Header';

const Nav = () => {
	const navigation = useNavigate();
	const location = useLocation();

	const NavIcon = ({ isRootPaths, path, icon, ...props }: any) => {
		const selected = isRootPaths
			? location.pathname === '/' || location.pathname.includes('events')
			: location.pathname.includes(path);
		const color = selected ? 'secondary.main' : 'primary.light';
		return (
			<IconBox
				textColor={color}
				iconButton={
					<IconButton sx={{ color: color }} onClick={() => navigation(path)}>
						{icon}
					</IconButton>
				}
				{...props}
			/>
		);
	};

	return (
		<Stack
			direction='row'
			justifyContent='space-around'
			sx={{
				height: `${NAV_HEIGHT}px`,
				width: '100%',
				bgcolor: 'primary.main',
				position: 'fixed',
				zIndex: 1,
				bottom: 0,
			}}
		>
			<NavIcon icon={<LocalBarIcon />} text='Events' path='/myEvents' />
			<NavIcon icon={<ContactsIcon />} text='Network' path='/contact' />
			<NavIcon icon={<DeckIcon />} text='Home' path='/' isRootPaths />
			<NavIcon icon={<PersonIcon />} text='Profile' path='/user' />
		</Stack>
	);
};

export default Nav;
