import { useCallback } from 'react';
import { useSWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';

interface UseGlobalStateReturn<Data> {
	data: Data;
	trigger: (data: Data) => void;
}

const useGlobalState = <Data>(key: string): UseGlobalStateReturn<Data> => {
	const { mutate } = useSWRConfig();
	const { data } = useSWRImmutable(key, null, { revalidateOnMount: false });

	const trigger = useCallback(
		(value: any) => mutate(key, value, false),
		[key, mutate]
	);

	return { data, trigger };
};
export default useGlobalState;
