import axios, {
	AxiosRequestConfig,
	AxiosRequestHeaders,
	AxiosResponse,
	AxiosError,
} from 'axios';
import store from 'store2';

const API_URL = 'https://sproudit-dev.apps.ffff0h.tw/';
const REFRESH_URL = `${API_URL}/auth/jwt/refresh-token`;

type PromiseQueue = {
	resolve: (value?: unknown) => void;
	reject: (reason?: unknown) => void;
}[];

let isRefreshing = false;

let queue: PromiseQueue = [];

const ajax = axios.create({
	baseURL: API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

const interceptHeaders = (config: AxiosRequestConfig) => {
	const headers: AxiosRequestHeaders = {
		'Content-Type': 'application/json',
		...config.headers,
	};

	const authorization = store.get('token');
	if (authorization) {
		headers.Authorization = `Bearer ${authorization}`;
	}

	return {
		...config,
		headers,
	};
};

const successInterceptor = (response: AxiosResponse) => {
	return response;
};

const requestRefreshToken = async (originalRequest: any): Promise<any> => {
	try {
		isRefreshing = true;
		// 不使用ajax instance才不會進入 jwtHandler迴圈
		const refreshToken = store.get('refreshToken');
		if (!refreshToken) throw new Error('No refresh token available');

		const response = await axios.post(REFRESH_URL, {
			refreshToken: refreshToken,
		});
		store.set('token', response.data.accessToken);

		const originalResponse = await ajax(originalRequest);
		resolveQueue();
		return originalResponse;
	} catch (error: any) {
		rejectQueue();
		const status = error?.response?.status;
		switch (status) {
			case 401:
				return Promise.reject(new Error('Refresh token was expired'));
			default:
				break;
		}
	} finally {
		isRefreshing = false;
	}
};

const resolveQueue = () => {
	queue.forEach((p) => p.resolve());
	queue = [];
};

const rejectQueue = () => {
	queue.forEach((p) => p.reject());
	queue = [];
};

const authTokenHandler = async (error: AxiosError) => {
	const originalRequest = error.config;
	if (isRefreshing) {
		return new Promise((resolve, reject) => {
			queue.push({ resolve, reject });
		})
			.then(() => {
				return ajax(originalRequest);
			})
			.catch(Promise.reject);
	} else {
		return await requestRefreshToken(originalRequest);
	}
};

const failureInterceptor = (error: AxiosError) => {
	if (error.isAxiosError && error.response) {
		const { response } = error;
		switch (response?.status) {
			case 401:
				if (response?.data?.detail === 'Unauthorized') {
					return authTokenHandler(error);
				} else {
					return Promise.reject(error);
				}
			case 500:
				return Promise.reject(error);
			default:
				return Promise.reject(response);
		}
	}
	return Promise.reject(error);
};

ajax.interceptors.request.use(interceptHeaders);
ajax.interceptors.response.use(successInterceptor, failureInterceptor);

export default ajax;
