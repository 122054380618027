import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import routes from './route';

const App = () => {
	const components = useRoutes(routes);

	return <Suspense fallback={<CircularProgress />}>{components}</Suspense>;
};

export default App;
