import type { Components } from '@mui/material/styles/components';
import { PRIMARY, PRIMARY_LIGHT, SECONDARY, SECONDARY_LIGHT } from '../colors';

const MuiButton: Components['MuiButton'] = {
	styleOverrides: {
		root: {
			color: 'black',
			fontSize: '18px',
			fontWeight: 600,
			borderRadius: '8px',
			cursor: 'pointer',
		},
	},
	variants: [
		{
			props: {
				variant: 'contained',
				color: 'primary',
			},
			style: {
				color: 'white',
				background: PRIMARY,
				'&:disabled': {
					background: 'graylight',
				},
			},
		},
		{
			props: {
				variant: 'outlined',
				color: 'primary',
			},
			style: {
				color: PRIMARY,
				background: 'white',
				borderColor: PRIMARY,
				boxShadow: `4px 8px 8px  rgba(0, 0, 0, 0.05)`,

				'&:disabled': {
					background: 'graylight',
					borderColor: 'gray',
					boxShadow: `4px 8px 8px  rgba(0, 0, 0, 0.05)`,
				},
			},
		},
		{
			props: {
				variant: 'secondary',
				color: 'primary',
			},
			style: {
				color: SECONDARY,
				background: 'white',
				'&:disabled': {
					color: SECONDARY_LIGHT,
					background: 'white',
				},
			},
		},
		{
			props: {
				variant: 'light',
				color: 'primary',
			},
			style: {
				background: 'white',
				boxShadow: `4px 8px 8px  rgba(0, 0, 0, 0.05)`,
				'&:disabled': {
					background: SECONDARY_LIGHT,
				},
			},
		},
		{
			props: {
				variant: 'text',
				color: 'primary',
			},
			style: {
				color: 'blue',
				'&:disabled': {
					color: 'gray',
				},
			},
		},
	],
};

export default MuiButton;
