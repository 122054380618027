import { lazy } from 'react';

const ContactMemoPage = lazy(
	() => import('./ContactMemoPage' /* webpackChunkName: 'ContactMemoPage' */)
);
const CreateMemoPage = lazy(
	() => import('./CreateMemoPage' /* webpackChunkName: 'CreateMemoPage' */)
);

export { CreateMemoPage, ContactMemoPage };
