import * as yup from 'yup';

export const passwordSchema = yup
	.string()
	.default('')
	.required()
	.min(8, 'Minimum 8 characters')
	.max(32, 'Limited to 32 characters')
	.matches(
		/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/,
		'Minimum 8 characters, at least 1 upper case English letter, 1 lower case English letter, 1 number and 1 special character'
	);

export const emailSchema = yup
	.string()
	.default('')
	.test((value, context) => {
		const isValid = /[\w-]+@([\w-]+\.)+[\w-]{2,4}/.test(value);
		if (value && !isValid) {
			return context.createError({
				message: 'Invalid email',
			});
		}
		return true;
	});

export const confirmPasswordSchema = (key: string) =>
	yup.string().oneOf([yup.ref(key), null], 'Confirm password is not correct!');

export const urlSchema = yup
	.string()
	.url('Please enter valid URL')
	.required('Enter valid URL');

export const contentSchema = (maxLength = 300) =>
	yup
		.string()
		.test(
			`length < ${maxLength}`,
			`Limited to ${maxLength} words \n Want to Become our premium member.`,
			(v: any) => v.length < maxLength
		);
export const linkedInURLSchema = yup.string().url();
