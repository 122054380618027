import { useWatch } from 'react-hook-form';

interface IWatched {
	name: string;
	render: (value: any) => JSX.Element | null;
}

const Watched = ({ name, render }: IWatched) => {
	const value = useWatch({ name });
	return render(value) || null;
};

export default Watched;
