import { useCallback, useState, useEffect } from 'react';
import * as yup from 'yup';
import useSWR from 'swr';
import store from 'store2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import {
	FormProvider,
	PasswordInputField,
	InputField,
} from '~/components/Form';
import Modal from '~/components/Modal';
import Button from '~/components/Button';
import useGlobalState from '~/hooks/useGlobalState';
import useSWRMutation from '~/hooks/useSWRMutation';
import { emailSchema, passwordSchema } from '~/utils/schema';
import type { IUser } from '~/types/apis/user';

const DEFAULT_VALUES = {
	username: '',
	password: '',
};

const schema = yup.object().shape({
	username: emailSchema.required(),
	password: passwordSchema,
});

interface ILoginModal {
	open?: boolean;
}

const LoginModal = ({ open = true }: ILoginModal) => {
	const [openModal, setOpenModal] = useState(open);
	const styles = useStyle();
	const navigate = useNavigate();
	const { trigger: setAuth } = useGlobalState('isAuth');

	const { trigger, isMutating } = useSWRMutation(
		{
			url: '/auth/jwt/login',
			method: 'POST',
		},
		{
			onSuccess: (data) => {
				setOpenModal(false);
				setAuth(true);
				navigate('/myEvents');
				if (data?.accessToken) store.set('token', data.accessToken);
				if (data?.refreshToken) store.set('refreshToken', data.refreshToken);
			},
		}
	);

	const onModalClose = useCallback(() => {
		setOpenModal(false);
		navigate(-1);
	}, [navigate, setOpenModal]);

	return (
		<Modal open={openModal} onClose={() => onModalClose()}>
			<Stack spacing={2} sx={styles.loginBox}>
				<Typography color='primary' variant='h1' sx={styles.brand}>
					Sproudit
				</Typography>
				<FormProvider
					defaultValues={DEFAULT_VALUES}
					schema={schema}
					onSubmit={(values: any) => {
						trigger({ data: values }, { notificationOnSuccess: false });
					}}
					render={() => {
						return (
							<>
								<InputField name='username' label='E-mail' />
								<PasswordInputField name='password' label='Password' />
								<Button type='submit' loading={isMutating} sx={{ mt: 6 }}>
									Login
								</Button>
							</>
						);
					}}
				/>
				<Button variant='text' onClick={() => navigate('/registration')}>
					Creating an account
				</Button>
			</Stack>
		</Modal>
	);
};

const useStyle = () => ({
	loginBox: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '54px',
		textAlign: 'center',
	},
	brand: {
		fontWeight: 'bold',
	},
	description: {
		lineHeight: 1.2,
		fontWeight: 'bold',
	},
	btnBox: {
		pt: 6,
	},
});
export default LoginModal;
