import { ReactElement } from 'react';
import { SxProps } from '@mui/system';
import Stack from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';

export interface IconBoxProps {
	iconButton?: ReactElement;
	text?: string | number;
	textVariant?: TypographyProps['variant'];
	textColor?: TypographyProps['color'];
	containerSx?: SxProps;
}

const IconBox = ({
	iconButton,
	text,
	containerSx,
	textVariant = 'h6',
	textColor = 'primary',
}: IconBoxProps) => {
	return (
		<Stack
			direction='column'
			alignItems='center'
			justifyContent='center'
			sx={{ ...containerSx }}
		>
			{iconButton}
			<Typography variant={textVariant} color={textColor}>
				{text}
			</Typography>
		</Stack>
	);
};

export default IconBox;
