import { ReactNode, useMemo } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { CssBaseline } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorPage from '~/pages/ErrorPage';
import Container from './Container';
import Header from './Header';
import Nav from './Nav';
import { CONTENT_TOP, NAV_HEIGHT } from './Header';

interface WrapperProps {
	loading?: boolean;
	children?: ReactNode;
	showLeftElement?: boolean;
	rightElement?: ReactNode;
	hideNav?: boolean;
}

const Wrapper = ({
	loading,
	children,
	showLeftElement,
	hideNav,
	rightElement,
}: WrapperProps) => {
	return (
		<Container>
			<CssBaseline />
			<Header showLeftElement={showLeftElement} rightElement={rightElement} />
			<Stack
				sx={{
					width: '100vw',
					pt: `${CONTENT_TOP}px`,
					pb: `${NAV_HEIGHT}px`,
					position: 'relative',
					overflow: 'hidden',
				}}
			>
				{loading && (
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<CircularProgress />
					</Box>
				)}
				<Box
					sx={{
						overflow: 'scroll',
						px: 2,
					}}
				>
					{children ? children : <ErrorPage />}
				</Box>
			</Stack>
			{!hideNav && <Nav />}
		</Container>
	);
};

export default Wrapper;
