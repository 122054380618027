import Box from '@mui/material/Box';

const Container = ({ children }: any) => {
	return (
		<Box
			sx={{
				width: '100vw',
				height: 'auto',
				display: 'flex',
			}}
		>
			{children}
		</Box>
	);
};

export default Container;
