import { createTheme, alpha } from '@mui/material';
import { orange, green, common, blue } from '@mui/material/colors';
import MuiButton from './components/MuiButton';
import { PRIMARY, SECONDARY, PRIMARY_LIGHT, SECONDARY_LIGHT } from './colors';

const defaultTheme = createTheme({
	palette: {
		primary: {
			light: PRIMARY_LIGHT,
			main: PRIMARY,
			dark: alpha(PRIMARY, 1.2),
		},
		secondary: {
			light: common.white,
			main: common.white,
			dark: common.black,
		},
		error: {
			light: SECONDARY_LIGHT,
			main: SECONDARY,
			dark: '#a52a13',
		},
		warning: {
			light: orange[300],
			main: orange[500],
			dark: orange[700],
		},
		info: {
			light: blue[300],
			main: blue[500],
			dark: blue[700],
		},
		success: {
			light: green[300],
			main: green[500],
			dark: green[700],
		},
		text: {
			primary: common.black,
			secondary: 'rgba(0, 0, 0, 0.54)',
			disabled: 'rgba(0, 0, 0, 0.38)',
		},
		divider: '#DFDFDF',
		background: {
			paper: common.white,
			default: common.white,
		},
	},
	spacing: 8,
	typography: {
		h1: {
			fontSize: '22px',
			fontWeight: 600,
			lineHeight: '24px',
		},
		h2: {
			fontSize: '18px',
			fontWeight: 600,
			lineHeight: '20px',
		},
		h3: {
			fontSize: '18px',
			fontWeight: 600,
			lineHeight: '20px',
		},
		h4: {
			fontSize: '16px',
			fontWeight: 600,
			lineHeight: '18px',
		},
		h5: {
			fontSize: '14px',
			fontWeight: 600,
			lineHeight: '16px',
		},
		h6: {
			fontSize: '14px',
			fontWeight: 400,
			lineHeight: '16px',
		},
	},
	components: { MuiButton },
});

export default defaultTheme;
