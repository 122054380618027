import { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { Modal as MuiModal } from '@mui/material';

interface ModalProps {
	open: boolean;
	onClose: Function;
	children?: ReactElement;
}

const Modal = ({ open, onClose, children }: ModalProps) => {
	return (
		<MuiModal
			open={open}
			onClose={() => {
				onClose();
			}}
		>
			<Box
				sx={{
					position: 'absolute' as 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					bgcolor: 'background.paper',
					boxShadow: 24,
					p: 0,
				}}
			>
				{children}
			</Box>
		</MuiModal>
	);
};

export default Modal;
